$indent: 20px;
$radius: 5px;
$primaryColor: #00a9e0;

@mixin hint {
  color: #777;
  font-size: 0.9rem;
}
@mixin radius {
  border-radius: $radius;
}
@mixin mobile {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 700px) {
    @content;
  }
}

@mixin apple {
  @media not all and (min-resolution: 0.001dpcm) {
    body {
      background: linear-gradient(-120deg, #021b79, #0575e6, #205e9b);
    }
  }
}

@mixin primarySchema {
  color: #fff;
  background-color: $primaryColor;
}

@mixin whiteSchema {
  color: #444;
  background-color: #fff;
}
