@import "../../../resources/style/mixix.scss";

.component {
}

.groupFields {
  margin-top: $indent;
}
.groupFieldsWrap {
  display: flex;
  > *:not(:last-child) {
    margin-right: $indent/2;
  }
}
