* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button,
input {
  border: none;
  box-shadow: none;
  border-radius: 30px;
  font-family: inherit;
  color: inherit;
  font-size: 1rem;
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
}
fieldset {
  border: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: underline;
}
ul {
  list-style: none;
}
h1 {
  font-weight: normal;
  font-size: 2rem;
}
body {
  line-height: 1.4;
  font-family: -apple-system, "Open Sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

small {
  opacity: 0.7;
  font-size: 0.85rem;
}
